import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BarChart } from 'react-feather';
import logo from '../../../assets/images/merchanme-logo.png';
import { MENUITEMS } from '../../../constant/menu';
import { Link } from 'react-router-dom';
import { translate } from 'react-switch-lang';
import configDB from '../../../data/customizer/config';
import { useService } from "../../../hooks/useService";
import { VIEWS_ROLES } from '../../../utils/enums';
import { saveValue } from '../../../utils/storage';
import { useAuth0 } from "@auth0/auth0-react";
import { getSidebarEmbeddedReportId, getPowerBIReportIdByClientId } from "../../../config.js";

const getConvertedDateTime = (datetime) => {
    let hour = datetime.getHours();
    let minutes = datetime.getMinutes();
    if (hour < 10)
        hour = `0${hour}`
    
    if (minutes < 10)
        minutes = `0${minutes}`
    return `${datetime.getDate()}/${datetime.getMonth() + 1}/${datetime.getFullYear().toString().substring(2)} ${hour}:${minutes}`
}

const Sidebar = (props) => {
    const [margin, setMargin] = useState(0);
    const [width, setWidth] = useState(0);
    const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(true);
    const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true);
    const [hideRightArrow, setHideRightArrow] = useState(true);
    const [hideLeftArrow, setHideLeftArrow] = useState(true);
    const [menu, setMenu] = useState([]);
    const wrapper = configDB.data.settings.sidebar.wrapper;
    const layout = useSelector(content => content.Customizer.layout);
    const { user } = useAuth0();
    const service = useService();
    var defaultReportId = getSidebarEmbeddedReportId();

    useEffect(() => {
        async function init() {
            if(menu.length > 0)
                return

            window.addEventListener('resize', handleResize)
            handleResize();
            await getPermissions();
            
            setTimeout(() => {
                const elmnt = document.getElementById("myDIV");
                const menuWidth = elmnt.offsetWidth;
                // setMenuWidth(menuWidth)
                if (menuWidth > window.innerWidth) {
                    setHideRightArrow(false);
                    setHideLeftArrowRTL(false);
                } else {
                    setHideRightArrow(true);
                    setHideLeftArrowRTL(true);
                }
            }, 500)
            return () => {
                window.addEventListener('resize', handleResize)
            }
        }
        init();
    }, [user]);

    const handleResize = () => {
        setWidth(window.innerWidth - 310);
    }

    const getClients = async () => {
        var list;
        await service.get("/api/client/list")
            .then(async response => {
                list = response?.data?.data;
            });
        return list;
    }

    const getPermissions = async () => {
        if(!user)
        return []        

        await service.get(`/api/account/access`)
        .then(async response => {
            var permissions = response.data.data

            if(permissions?.length > 0) {
                saveValue('authorization', permissions)
            }
            var options = [];

            if(permissions.includes(VIEWS_ROLES.ALL)){
                options = MENUITEMS
            } else {
                options = MENUITEMS.filter(m => permissions.includes(m.permission))
            }
            
            setMenu([...options]);
            
            var reportId = defaultReportId;

            //get clientId of authenticated user to get specific powerBI reportId
            if(!permissions.includes(VIEWS_ROLES.ALL)){
                var clients = await getClients();
                if(clients.length > 0) {
                    reportId = getPowerBIReportIdByClientId(clients[0].id);
                }
            }

            await service.get(`/api/powerbi/ReportPages/${reportId}`)
                .then(async reportsResponse => {
                    let report = reportsResponse.data.data;

                    let lastRefreshDate = new Date(report?.lastRefreshUTCDate);

                    if(lastRefreshDate)
                        lastRefreshDate = getConvertedDateTime(lastRefreshDate);

                    let option = {
                        id: reportId,
                        title: report.name,
                        icon: BarChart,
                        type: 'sub',
                        titleDetail: lastRefreshDate && `Atualizado em ${lastRefreshDate}`,
                        children: report.pages.map(page => (
                            {
                                title: page.displayName,
                                path: `/report/${report.id}/${page.name}`,
                                icon: BarChart,
                                type: 'link'
                            }))
                    }

                    let reportOptionIndex = options.findIndex(o => o.id === 'report');
                    options[reportOptionIndex] = option;
                    setMenu([...options]);
                });

            var currentUrl = window.location.pathname;
            options.filter(items => {
                if (items.path === currentUrl)
                    setNavActive(items)
                if (!items.children) return false
                items.children.filter(subItems => {
                    if (subItems.path === currentUrl)
                        setNavActive(subItems)
                    if (!subItems.children) return false
                    subItems.children.filter(subSubItems => {
                        if (subSubItems.path === currentUrl)
                            setNavActive(subSubItems)
                    })
                })
            })

            setMenu([...options])
            setMenu([...options])
            return response.data.data;
        })
        .catch(error => []);
    }
    const setNavActive = (item) => {
        var options = [...menu]
        options.filter(menuItem => {

            if (menuItem !== item)
                menuItem.active = false
            if (menuItem.children && menuItem.children.includes(item))
                menuItem.active = true
            if (menuItem.children) {
                menuItem.children.filter(submenuItems => {
                    if (submenuItems.children && submenuItems.children.includes(item)) {
                        menuItem.active = true
                        submenuItems.active = true
                    }
                })
            }
        })
        item.active = !item.active
        setMenu([...options])
    }

    // Click Toggle menu
    const toggletNavActive = (item) => {
        var options = [...menu]

        if(item.active && item.type === 'sub' && item.active === true) {
            let currentItem = options.find(o => o.id === item.id);
            currentItem.active = false
            setMenu([...options])
            return;
        }

        options.forEach(a => {
            if (options.includes(item))
                a.active = false
            if (!a.children) return false
            a.children.forEach(b => {
                if (a.children.includes(item)) {
                    b.active = false
                }
                if (!b.children) return false
                b.children.forEach(c => {
                    if (b.children.includes(item)) {
                        c.active = false
                    }
                })
            })
        });

        item.active = !item.active
        setMenu([...options])
    }

    const scrollToRight = () => {
        const elmnt = document.getElementById("myDIV");
        const menuWidth = elmnt.offsetWidth;
        const temp = menuWidth + margin;
        if (temp < menuWidth) {
            setMargin(-temp);
            setHideRightArrow(true);
        }
        else {
            setMargin(margin => margin += (-width));
            setHideLeftArrow(false);
        }
    }

    const scrollToLeft = () => {
        // If Margin is reach between screen resolution
        if (margin >= -width) {
            setMargin(0)
            setHideLeftArrow(true);
        }
        else {
            setMargin(margin => margin += width);
            setHideRightArrow(false);
        }
    }


    const scrollToLeftRTL = () => {
        if (margin <= -width) {
            setMargin(margin => margin += -width);
            setHideLeftArrowRTL(true);
        }
        else {
            setMargin(margin => margin += -width);
            setHideRightArrowRTL(false);
        }
    }

    const scrollToRightRTL = () => {
        const temp = width + margin
        // Checking condition for remaing margin
        if (temp === 0) {
            setMargin(temp);
            setHideRightArrowRTL(true);
        }
        else {
            setMargin(margin => margin += width);
            setHideRightArrowRTL(false);
            setHideLeftArrowRTL(false);
        }
    }

    return (
        <Fragment>
            <div className="page-sidebar">
                <div className="main-header-left d-none d-lg-block">
                    <div className="logo-wrapper compactLogo">
                        <Link to="/campaigns/list">
                            <img className="blur-up lazyloaded" src={logo} alt="logo" />
                            <img className="blur-up lazyloaded" style={{ width: '210px' }} src={logo} alt="logo" />
                        </Link>
                    </div>
                </div>
                <div className="sidebar custom-scrollbar">
                    <ul
                        className="sidebar-menu"
                        id="myDIV"
                        style={wrapper === 'horizontal_sidebar' ? layout === 'rtl' ?
                            { 'marginRight': margin + 'px' } : { 'marginLeft': margin + 'px' } : { margin: '0px' }}
                    >
                        <li className={`left-arrow ${layout === 'rtl' ? hideLeftArrowRTL ? 'd-none' : '' : hideLeftArrow ? 'd-none' : ''}`}
                            onClick={(wrapper === 'horizontal_sidebar' && layout === 'rtl') ? scrollToLeftRTL : scrollToLeft}><i className="fa fa-angle-left"></i></li>
                        {
                            menu.map((menuItem, i) =>
                                <li className={`${menuItem.active ? 'active' : ''}`} key={i}>
                                    {(menuItem.sidebartitle) ?
                                        <div className="sidebar-title">{menuItem.sidebartitle}</div>
                                        : ''}
                                    {(menuItem.type === 'sub') ?
                                        <div className='sidebar-header' onClick={() => toggletNavActive(menuItem)}>                                            
                                            <div className='sidebar-header-itemgroup pointer '>
                                                <div><menuItem.icon /></div>
                                                <div>
                                                    <span className='sidebar-header_menu-item'>{props.t(menuItem.title)}</span><br/>
                                                    <span className='sidebar-header_menu-item menu-item_title-detail'>{props.t(menuItem.titleDetail)}</span>
                                                </div>
                                                <span className="arrowbtn arrowbtn-up"></span>
                                            </div>
                                        </div>
                                        : ''}
                                    {(menuItem.type === 'link') ?
                                        <Link
                                            to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                                            className={`sidebar-header ${menuItem.active ? 'active' : ''}`}

                                            onClick={() => toggletNavActive(menuItem)}
                                        >
                                            <menuItem.icon />

                                            <div className='sidebar-header-itemgroup'>
                                            <span>{props.t(menuItem.title)}</span>
                                                {menuItem.children ?
                                                    <span className="arrowbtn arrowbtn-up"></span> : ''}
                                            </div>
                                            
                                        </Link>
                                        : ''}
                                    {menuItem.children ?
                                        <ul
                                            className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''}`}
                                            style={menuItem.active ? { opacity: 1, transition: 'opacity 500ms ease-in' } : {}}
                                        >
                                            {menuItem.children.map((childrenItem, index) =>
                                                <li key={index} className={childrenItem.children ? childrenItem.active ? 'active' : '' : ''}>
                                                    {(childrenItem.type === 'sub') ?
                                                        <a className="sidebar-header" href="#" onClick={() => toggletNavActive(childrenItem)} >
                                                            <div className='sidebar-header-itemgroup'>
                                                            {props.t(childrenItem.title)} <span className="arrowbtn arrowbtn-up"></span>                                                
                                                            </div>
                                                        </a>
                                                        : ''}

                                                    {(childrenItem.type === 'link') ?
                                                        <Link
                                                            to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                                                            className={childrenItem.active ? 'active' : ''}
                                                            onClick={() => toggletNavActive(childrenItem)}
                                                        >
                                                            <div className="sidebar-item-dot"></div>{props.t(childrenItem.title)} </Link>
                                                        : ''}
                                                    {childrenItem.children ?
                                                        <ul className={`sidebar-submenu ${childrenItem.active ? 'menu-open' : 'active'}`}>
                                                            {childrenItem.children.map((childrenSubItem, key) =>
                                                                <li className={childrenSubItem.active ? 'active' : ''} key={key}>
                                                                    {(childrenSubItem.type === 'link') ?
                                                                        <Link
                                                                            to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                                                            className={childrenSubItem.active ? 'active' : ''}
                                                                            onClick={() => toggletNavActive(childrenSubItem)}
                                                                        >
                                                                            {props.t(childrenSubItem.title)}</Link>
                                                                        : ''}
                                                                </li>
                                                            )}
                                                        </ul>
                                                        : ''}
                                                </li>
                                            )}
                                        </ul>
                                        : ''}
                                </li>
                            )
                        }
                        <li className={`right-arrow ${layout === 'rtl' ? hideRightArrowRTL ? 'd-none' : '' : hideRightArrow ? 'd-none' : ''}`}
                            onClick={(wrapper === 'horizontal_sidebar' && layout === 'rtl') ? scrollToRightRTL : scrollToRight}><i className="fa fa-angle-right"></i></li>
                    </ul>
                </div>
            </div>
        </Fragment>
    );
};

export default translate(Sidebar);

