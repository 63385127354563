import { VISITSSTATUS, RESERVATIONS_VISITS_STATUS } from "./enums";

export  const getPercentColor = (percent) => {
    
    if(isNaN(percent))
        return;
    
    if(percent < 50)
        return 'error-color';
    else if(percent < 70)
        return 'yellow-color';
    else 
        return 'success-color';
}

export const getAiStatus = (status) => {
    switch(status) {
        case 0:
            return VISITSSTATUS[0];
        case 1:
            return VISITSSTATUS[1];
        case 2:
            return VISITSSTATUS[2];
        default:
            return '-';
    }
}

export const getVisitsReservationsStatus = (status) => {
    switch(status) {
        case 0:
            return RESERVATIONS_VISITS_STATUS[0];
        case 1:
            return RESERVATIONS_VISITS_STATUS[1];
        case 2:
            return RESERVATIONS_VISITS_STATUS[2];
        case 3:
            return RESERVATIONS_VISITS_STATUS[3];
        case 4:
            return RESERVATIONS_VISITS_STATUS[4];
        case 5:
            return RESERVATIONS_VISITS_STATUS[5];
        default:
            return '-';
    }
}


export const getVisitsReservationsClass = (status) => {

    switch(status) {
        case 0, 1:
            return 'visit-1';
        case 3:
            return 'visit-2';
        case 4, 5:
            return 'visit-canceled';
        default:
            return 'visit-1';
    }
}

export const getPointOfSaleStatus = (status) => {
    switch(status) {
        case 0:
            return 'Excel';
        case 2:
            return 'App';
        default:
            return '-';
    }
}

export const getPointOfSaleStatusClass = (status) => {
    switch(status) {
        case 0:
            return 'visit-2';
        case 2:
            return 'visit-0';
        default:
            return '';
    }
}

export const getAiClass = (status) => {

    switch(status) {
        case 0:
            return 'error-color';
        case 1:
            return 'yellow-color';
        case 2:
            return 'success-color';
        default:
            return '';
    }
}