import React, { Fragment } from "react";

const combosColumns = [
    {
        columns: [
            {
                Header: "Nome",
                accessor: "name",
            },
            {
                Header: "Pesquisas",
                accessor: "numberOfSurveys",
                width: 150
            },
            {
                Header: "Data Criação",
                accessor: "registerDate",
                width: 150
            },
            {
                Header: "Data Expiração",
                accessor: "expirationDate",
                width: 150
            }
        ],
    },
];

const reportedErrorsPdv = [
    {
        columns: [
            {
                Header: "Motivo",
                accessor: "reportedErrorTypeName",
                width: 200
            },
            {
                Header: "Nome usuário",
                accessor: "userName",
                width: 200
            },
            {
                Header: "E-mail do usuário",
                accessor: "userEmail",
                width: 150
            },
            {
                Header: "Data relato",
                accessor: "registerDate",
                width: 150
            },            
            {
                Header: "Observação",
                accessor: "observation"
            }
        ],
    }
]

const userInvitationsColumns = [
    {
        columns: [
            {
                Header: "Código",
                accessor: "code",
                width: 120
            },
            {
                Header: "Data de Criação",
                accessor: "registerDate",
                width: 160
            },
            {
                Header: "Data de Expiração",
                accessor: "expirationDate",
                width: 160
            },
            {
                Header: "E-mail do usuário convidado",
                accessor: "invitedUserEmail",
            },
            {
                Header: "Data de Cadastro",
                accessor: "usageDate",
                width: 160
            },
            {
                Header: "Visitas Realizadas",
                accessor: "invitedUserVisitsAmount",
                width: 180
            },
            {
                Header: "Recompensa aprovada",
                accessor: "rewardGranted",
                width: 215
            },
        ],
    }
]

const pdvsColumns = [
    {
        columns: [
            {
                Header: "Nome",
                accessor: "name",
                // width: 300
            },
            {
                Header: "Origem",
                accessor: "status",
                width: 100
            },
            {
                Header: "Autorização",
                accessor: "authorization",
                width: 70
            },
            {
                Header: "Endereço",
                accessor: "address",
                // width: 180
            },
            {
                Header: "Cidade",
                accessor: "city",
                width: 130
            },
            {
                Header: "Região",
                accessor: "region",
                width: 100
            },
            {
                Header: "Estado",
                accessor: "state",
                width: 75
            },
            {
                Header: "E-mail",
                accessor: "ownerEmail",
                width: 130
            },
            {
                Header: "Telefone",
                accessor: "ownerPhoneNumber",
                width: 130
            },
            {
                Header: "Qtd Erros",
                accessor: "reportedErrorsAmount",
                width: 100
            },
            {
                Header: <span className="pt-10 pb-5">Editar</span>,
                accessor: "details",
                width: 80
            }
        ],
    },
];

const simulationTable = [
    {
        columns: [
            {
                Header: "Identificador",
                accessor: "id",
                width: 80
            },
            {
                Header: "Cidade",
                accessor: "city",
                width: 150
            },
            {
                Header: "PDVs",
                accessor: "pointOfSaleNumber",
                width: 70
            },
            {
                Header: "Total Km",
                accessor: "distanceFix",
                width: 90
            }
        ],
    },
];

const generateCombosColumns = [
    {
        columns: [
            {
                Header: "Nome",
                accessor: "name",
                sortable: false
            },
            {
                Header: "Autorização",
                accessor: "authorization",
                width: 80
            },
            {
                Header: "Cidade",
                accessor: "city",
                sortable: false
            },
            {
                Header: "Região",
                accessor: "region",
                sortable: false
            },
            {
                Header: "Estado",
                accessor: "state",
                sortable: false,
                width: 75
            },
            {
                Header: "Tipo",
                accessor: "type",
                sortable: false
            },            
        ],
    },
];

const visitsColumns = [
    {
        columns: [
            {
                Header: "Detalhes",
                accessor: "details",
                width: 60
            },
            {
                Header: "Aprovar",
                accessor: "approve",
                width: 100
            },
            {
                Header: "Aprovação",
                accessor: "approved",
                width: 100
            },
            {
                Header: "PDV",
                accessor: "posName"
            },
            {
                Header: "Status",
                accessor: "status",
                width: 158
            },
            {
                Header: "Avaliação AI",
                accessor: "percentEvaluation",
                width: 90
            },
            {
                Header: "Cidade",
                accessor: "posCity",
                width: 120
            },
            {
                Header: "Região",
                accessor: "posRegion",
                width: 150
            },
            {
                Header: "Estado",
                accessor: "posState",
                width: 75
            },
            {
                Header: "Finalizada",
                accessor: "finishDate",
                width: 120
            },
            {
                Header: "E-mail usuário",
                accessor: "userEmail",
                width: 200
            },
            // {
            //     Header: "Qtd Erros",
            //     accessor: "reportedErrorsAmount",
            //     width: 100
            // },
            
        ],
    },
];

const gondolasImagesColumns = [
    {
        Header: "IMG",
        accessor: "img",
        width: 10
    },
    {
        Header: "Marca",
        accessor: "brand",
        width: 110
    },
    {
        Header: "Qntd",
        accessor: "count",
        width: 30
    },
    {
        Header: "Código",
        accessor: "code",
        width: 60
    },
    {
        Header: "EAN",
        accessor: "ean",
        width: 60
    },
    {
        Header: "Valor",
        accessor: "value",
        sortable: 30
    },
    {
        Header: "Avaliação",
        accessor: "evaluation",
        sortable: 40
    }
];

const surveysColumns = [
    {
        columns: [
            {
                Header: "Combo ID",
                accessor: "routeIdentifier",
                width: 120
            },
            {
                Header: "Expiração Combo",
                accessor: "routeExpirationDate",
                width: 110
            },
            {
                Header: "PDV",
                accessor: "pointOfSaleName"
            },
            {
                Header: "Criado em",
                accessor: "registerDate",
                width: 110
            },
            {
                Header: "Endereço",
                accessor: "address"
            },
            {
                Header: "Cidade",
                accessor: "city",
                width: 130
            },
            {
                Header: "Região",
                accessor: "region",
                width: 130
            },
            {
                Header: "Estado",
                accessor: "state",
                width: 75
            },
            {
                Header: "Visitas(qtd)",
                accessor: "visitsAmount",
                width: 100
            },
            {
                Header: "Valor",
                accessor: "value",
                width: 100
            }
        ]
    }
];


const campaignsColumns = [
    {
        twoLinesHeader: true,
        columns: [            
            {
                hint: (<div>
                <p>Data de</p>
                <p>Início</p>
            </div>),
                Header: <span className="pt-10 pb-5">Data Início</span>,
                accessor: "startDate",
                width: 110
            },
            {
                hint: (<div>
                    <p>Data de</p>
                    <p>Término</p>
                </div>),
                Header: <span className="pt-10 pb-5">Data Término</span>,
                accessor: "endDate",
                width: 130
            },
            {
                hint: 'Nome',
                Header: <span className="pt-10 pb-5">Nome</span>,
                accessor: "name"
            },         
            {
                hint: (<div>
                    <p>Status da</p>
                    <p>Execução</p>
                </div>),
                Header: <Fragment>
                        <p>Status da</p>
                        <p className="executionStatus">Execução</p>
                    </Fragment>,
                accessor: "currentStatusPercentage",
                width: 120
            },      
            {
                hint: <div>
                    <p>Status da</p>
                    <p>Criação</p>
                </div>,
                Header: (<Fragment>
                    <p>Status da</p>
                    <p className="creationStatus">Criação</p>
                </Fragment>),
                accessor: "createdStatusPercentage",
                width: 120
            },
            {
                hint: <div>
                    <p>Status</p>
                    <p>Planejado</p>
                </div>,
                Header: (<Fragment>
                    <p>Status</p>
                    <p className="planStatus">Planejado</p>
                </Fragment>),
                accessor: "expectedStatusPercentage",
                width: 120
            },
            {
                Header: (<Fragment>
                    <p>PDVs</p>
                    <p><span className="executionStatus">E</span>/<span className="creationStatus">C</span>/<span className="planStatus">P</span></p>
                </Fragment>),
                hint: (<div>
                    <p>Pontos de Venda</p>
                    <p>Executados/</p>
                    <p>Cadastrados/</p>
                    <p>Planejados</p>
                </div>),
                accessor: "pdvsVisited",
                width: 160
            },
            {
                Header: (<Fragment>
                    <p>Pesquisas</p>
                    <p><span className="executionStatus">E</span>/<span className="creationStatus">C</span>/<span className="planStatus">P</span></p>
                </Fragment>),
                hint: (<div>
                    <p>Pesquisas</p>
                    <p>Executadas/</p>
                    <p>Cadastradas/</p>
                    <p>Planejadas</p>
                </div>),
                accessor: "surveysVisited",
                width: 160
            },  
            {
                Header: (<Fragment>
                    <p>Reconhecimentos</p>
                    <p><span className="campaigns_list_recognition_products">Produtos</span>/<span className="campaigns_list_recognition_prices">Preços</span></p>
                </Fragment>),
                accessor: "recognizedItems",
                width: 175
            },
            {
                Header: <span className="pt-10 pb-5">Editar</span>,
                accessor: "details",
                width: 80
            }
        ]
    }
];

const usersColumns = [
    {
        columns: [
            {
                Header: <Fragment>
                    <p>Convites</p>
                    <p>G/U/V</p>
                </Fragment>,
                accessor: "invitations",
                width: 120,
                hint: (<div>
                    <p>Quantidade de Convites</p>
                    <p>Gerados/</p>
                    <p>Utilizados/</p>
                    <p>Com visitas realizadas</p>
                </div>),
            },
            {
                Header: <Fragment>
                    <p>Data</p>
                    <p>Registro</p>
                </Fragment>,
                accessor: "registerDate",
                width: 105
            },
            {
                Header: "Nome",
                accessor: "name",
                width: 200
            },
            {
                Header: "E-mail",
                accessor: "username",
                width: 250
            },
            {
                Header: "Telefone",
                accessor: "phoneNumber",
                width: 160
            },
            {
                Header: "Idade",
                accessor: "age",
                width: 90
            },
            {
                Header: "Cidade",
                accessor: "city",
                width: 130
            },
            {
                Header: "Estado",
                accessor: "state",
                width: 105
            },
            {
                Header: <Fragment>
                    <p>Visitas</p>
                    <p>Realizadas</p>
                </Fragment>,
                accessor: "numberOfVisits",
                width: 120
            },
            {
                Header: <Fragment>
                    <p>Última</p>
                    <p>Visita</p>
                </Fragment>,
                accessor: "lastVisit",
                width: 105
            },
            {
                Header: <Fragment>
                    <p>Local</p>
                    <p>Última Visita</p>
                </Fragment>,
                accessor: "location",
                width: 145
            },
            {
                Header: <Fragment>
                    <p>Pedido de</p>
                    <p>Pagamento</p>
                </Fragment>,
                accessor: "lastRequestWalletWithdraw",
                width: 105
            },
            {
                Header: "Chave PIX",
                accessor: "pixKey",
                width: 145
            },
            {
                Header: <Fragment>
                    <p>Pagamento</p>
                    <p>Efetuado</p>
                </Fragment>,
                accessor: "walletTotalPaid",
                width: 130
            },
            {
                Header: <Fragment>
                    <p>Pendente</p>
                    <p>na carteira</p>
                </Fragment>,
                accessor: "walletTotalPendingApproval",
                width: 130
            },
            {
                Header: <Fragment>
                    <p>Disponível</p>
                    <p>na carteira</p>
                </Fragment>,
                accessor: "walletTotalAvailable",
                width: 130
            },
            {
                Header: "Pagar",
                accessor: "makePayment",
                width: 80
            },
        ],
    },
];

const usersGroupsColumns = [
    {
        columns: [
            {
                Header: "Nome",
                accessor: "name",
                width: 350
            },
            {
                Header: "Usuários",
                accessor: "amountOfUsers",
                width: 180,
                hint: 'Quantidade de usuários'
            },
            {
                Header: "Combos",
                accessor: "amountOfRoutes",
                width: 180,
                hint: 'Quantidade de combos'
            },
            {
                Header: "Combos permitidos",
                accessor: "allowedToWorkOnRoute",
                hint: 'Permitido trabalhar em combos'
            },
            {
                Header: <span className="pt-10 pb-5">Editar</span>,
                accessor: "details",
                width: 80
            }
        ]
    }
];

const reportedErrorsColumns = [
    {
        columns: [
            {
                Header: "Data",
                accessor: "registerDate",
                width: 105
            },
            {
                Header: "Motivo",
                accessor: "reportedErrorTypeName",
                width: 200
            },
            {
                Header: "PDV",
                accessor: "pointOfSaleName",
                width: 300
            },
            {
                Header: "Endereço",
                accessor: "pointOfSaleAddress",
                width: 250
            },
            {
                Header: "Combo",
                accessor: "routeIdentifier",
                width: 100
            },
            {
                Header: "Observação",
                accessor: "observation"
            }
        ]
    }
];


const visitReservationsColumns = [
    {
        columns: [
            {
                Header: "Ponto de Venda",
                accessor: "posName"
            },
            {
                Header: "Estado",
                accessor: "posState",
                width: 75
            },
            {
                Header: "Cidade",
                accessor: "posCity",
                width: 120
            },
            {
                Header: "Região",
                accessor: "posRegion",
                width: 150
            },
            {
                Header: "Combo",
                accessor: "routeIdentifier",
                width: 150
            },
            {
                Header: "E-mail usuário",
                accessor: "userEmail",
                width: 200
            },
            {
                Header: "Reservada em",
                accessor: "reservationDate",
                width: 140
            },
            {
                Header: "Status",
                accessor: "status",
                width: 180
            },
            {
                Header: "Visita",
                accessor: "visitId",
                width: 60
            }
        ],
    },
];

const productsColumns = [
    {
        columns: [
            {
                Header: "Nome",
                accessor: "name",
                // width: 210
            },
            {
                Header: "EAN",
                accessor: "EAN",
                width: 135
            },
            {
                Header: "Marca",
                accessor: "brand",
                width: 160
            },
            {
                Header: "Fabricante",
                accessor: "manufacturer",
                width: 140
            },
            {
                Header: "Categoria",
                accessor: "category",
                width: 120
            },
            {
                Header: "Segmento",
                accessor: "segment",
                width: 130
            },
            {
                Header: "Subsegmento",
                accessor: "subSegment",
                width: 120
            },
            {
                Header: <Fragment>
                    <p>Preço</p>
                    <p>ideal</p>
                </Fragment>,
                accessor: "idealPrice",
                width: 85
            },
            {
                Header: <Fragment>
                    <p>Preço</p>
                    <p>Outlier</p>
                </Fragment>,
                accessor: "priceOutlierFactor",
                hint: (<div>
                    <p>Price</p>
                    <p>Outlier Factor</p>
                </div>),
                width: 85
            },
            {
                Header: <Fragment>
                    <p>Preço</p>
                    <p>Range</p>
                </Fragment>,
                accessor: "priceRangeFactor",
                hint: (<div>
                    <p>Price</p>
                    <p>Range Factor</p>
                </div>),
                width: 85
            },
            {
                Header: <Fragment>
                        <p>AI Filter</p>
                        <p>Keywords</p>
                    </Fragment>,
                accessor: "aIFilterKeywords",
                hint: 'Ordenação não permitida',
                width: 120
            },
            {
                Header: <Fragment>
                        <p>AI Search</p>
                        <p>Keywords</p>
                    </Fragment>,
                accessor: "aISearchKeywords",
                hint: 'Ordenação não permitida',
                width: 120
            },
            {
                Header: "Data Criação",
                accessor: "registerDate",
                width: 120
            },
            {
                Header: <span className="pt-10 pb-5">Editar</span>,
                accessor: "details",
                width: 80
            }
        ],
    },
];

export{ combosColumns, pdvsColumns, generateCombosColumns, simulationTable, visitsColumns, gondolasImagesColumns, reportedErrorsPdv, surveysColumns, campaignsColumns, usersColumns, reportedErrorsColumns, userInvitationsColumns, usersGroupsColumns, visitReservationsColumns, productsColumns };