import { states } from "../../../utils/helper";
import { RESERVATIONS_VISITS_STATUS } from "../../../utils/enums";

export const visitsFilters = (pointOfSaleTypes) => ([{
  name: 'POSName',
  value: '',
  title: 'Nome PDV'
},
{
  name: 'POSTypeName',
  value: -1,
  title: 'Tipo PDV',
  type: "select",
  options: pointOfSaleTypes
},
{
  name: 'POSState',
  value: -1,
  title: 'Estado PDV',
  type: "select",
  options: states
},
{
  name: 'POSCity',
  value: '',
  title: 'Cidade PDV'
},
{
  name: 'POSRegion',
  value: '',
  title: 'Região PDV'
},
{
  name: 'ProductEAN',
  value: '',
  title: 'EAN do Produto'
},
{
  name: 'ProductName',
  value: '',
  title: 'Nome do Produto'
},
{
  name: 'UserEmail',
  value: '',
  title: 'E-mail usuário'
},
{
  name: 'finishDate',
  valueStart: null,
  valueEnd: null,
  title: 'Data de conclusão',
  type: 'date-range-picker'
},
{
  name: 'percentEvaluation',
  value: -1,
  title: 'Avaliação AI',
  type: "select",
  options: [

    {
      name: 'Bem avaliada',
      value: 1
    },
    {
      name: 'Mal avaliada',
      value: 2
    },
    {
      name: 'Em branco',
      value: 'empty'
    }
  ]
},
{
  name: 'status',
  value: -1,
  title: 'Status da Visita',
  options: [
    {
      name: 'Aguardando AI',
      value: 0
    },
    {
      name: 'Enviado para AI',
      value: 1
    },
    {
      name: 'Processado pela AI',
      value: 2
    }
  ],
  type: "select",
},
{
  name: 'approved',
  value: -1,
  title: 'Visita aprovada',
  options: [
    {
      name: 'Aprovada',
      value: true
    },
    {
      name: 'Rejeitada',
      value: false
    },
    {
      name: 'Pendente de aprovação',
      value: 'empty'
    }
  ],
  type: "select",
}]
);

export const visitsReservationsFilters = [
  {
    name: 'POSName',
    value: '',
    title: 'Nome PDV'
  },
  {
    name: 'POSState',
    value: -1,
    title: 'Estado PDV',
    type: "select",
    options: states
  },
  {
    name: 'POSCity',
    value: '',
    title: 'Cidade PDV'
  },
  {
    name: 'POSRegion',
    value: '',
    title: 'Região PDV'
  },
  {
    name: 'UserEmail',
    value: '',
    title: 'E-mail usuário'
  },
  {
    name: 'registerDate',
    valueStart: null,
    valueEnd: null,
    title: 'Data da reserva',
    type: 'date'
  },
  {
    name: 'status',
    value: 0,
    title: 'Status das Reservas',
    options: [
      {
        name: RESERVATIONS_VISITS_STATUS[0],
        value: 0
      },
      {
        name: RESERVATIONS_VISITS_STATUS[2],
        value: 2
      },
      {
        name: RESERVATIONS_VISITS_STATUS[3],
        value: 3
      },
      {
        name: RESERVATIONS_VISITS_STATUS[4],
        value: 4
      },
      {
        name: RESERVATIONS_VISITS_STATUS[5],
        value: 5
      }
    ],
    type: "select",
  }
]