import React, { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import { useParams, useLocation} from 'react-router-dom';

import useForm from '../../hooks/useForm';
import { ROLES } from "../../utils/enums";
import { useService } from "../../hooks/useService";
import Loading from "../../components/common/loading";
import Input from '../../components/common/form/input';
import Select from "../../components/common/form/select";
import Breadcrumb from "../../components/common/breadcrumb";
import TextArea from '../../components/common/form/textarea';
import { Alert } from "../../components/common/modals/modals";
import { Switch } from '../../components/common/switch/switch.js';

const ProductForm = ({history}) => {

    const params = useParams();
    const service = useService();
    const location = useLocation();
    const [loading, setLoading] = React.useState(true);
    const [role, setRole] = React.useState(ROLES.EDIT);
    const [isNewRegister, setIsNewRegister] = React.useState(false);
    
    // PARAMETERS
    const name = useForm('name');
    const EAN = useForm('EAN');
    const isActive = useForm('isActive');
    const registerDate = useForm('date');
    const description = useForm('description');
    const weight = useForm('weight');
    const height = useForm('height');
    const width = useForm('width');
    const depth = useForm('depth');
    const updatedByUsername = useForm('updatedByUsername');
    const manufacturer = useForm('manufacturer');
    const segment = useForm('segment');
    const subSegment = useForm('subSegment');
    const region = useForm('region');
    const idealPrice = useForm('idealPrice');
    const priceOutlierFactor = useForm('priceOutlierFactor');
    const priceRangeFactor = useForm('priceRangeFactor');
    const aiFilterKeywords = useForm('aiFilterKeywords');
    const aiSearchKeywords = useForm('aiSearchKeywords');
    const brand = useForm('brand', -1);
    const [brandOptions, setBrandOptions] = React.useState([]);
    const productCategory = useForm('category', -1);
    const [productCategoriesOptions, setProductCategoriesOptions] = React.useState([]);

    const handleSubmit = async event => {
        event.preventDefault();
        name.validate();
        
        if (name.validate()) {
            isNewRegister? create() : update()
        }
    };

    const getProduct = async (id) => {
        await service.get(`/api/product/details/${id}`)
            .then(response => {
                const result = response?.data?.data;                

                if(result) {
                    name.setValue(result.name);
                    EAN.setValue(result.ean);
                    isActive.setValue(result.isActive);
                    let rd = new Date(result.registerDate);
                    registerDate.setValue(`${rd.getFullYear()}-${(rd.getMonth()+1).toString().padStart(2, '0')}-${rd.getDate().toString().padStart(2, '0')}`);
                    description.setValue(result.description);
                    weight.setValue(result.weight);
                    height.setValue(result.height);
                    width.setValue(result.width);
                    depth.setValue(result.depth);
                    updatedByUsername.setValue(result.updatedByUsername);
                    manufacturer.setValue(result.manufacturer);
                    segment.setValue(result.segment);
                    subSegment.setValue(result.subSegment);
                    region.setValue(result.region);
                    idealPrice.setValue(result.idealPrice);
                    priceOutlierFactor.setValue(result.priceOutlierFactor);
                    priceRangeFactor.setValue(result.priceRangeFactor);
                    aiFilterKeywords.setValue(result.aiFilterKeywords);
                    aiSearchKeywords.setValue(result.aiSearchKeywords);
                    brand.setValue(result.brandId);
                    productCategory.setValue(result.categoryId);
                    priceRangeFactor.setValue(result.priceRangeFactor);
                    priceRangeFactor.setValue(result.priceRangeFactor);
                }else {
                    console.log('ERROR')
                    history.replace('/product/list')
                }
                
                setLoading(false);
            })
            .catch(e => {
                console.log('ERROR: ', e)
                history.replace('/product/list')
            })
    }

    const getBrands = async () => {
        await service.get("/api/brand/list")
            .then(async response => {
                const result = response?.data?.data;
                setBrandOptions(result.map(r => ({
                    label: r.name,
                    name: r.name,
                    value: r.id,
                    id: r.id
                })))
                // setBrandOptions(response.data.data);
            });
    }

    const getProductCategories = async () => {
        await service.get(`/api/ProductCategory/list`)
            .then(response => {
                const result = response?.data?.data;
                if (result) {
                    setProductCategoriesOptions(result.map(r => ({
                        label: r.name,
                        name: r.name,
                        value: r.id,
                        id: r.id
                    })))
                }
            });
    }

    const getPayload = () => ({
            id: params.id,
            name: name.value,
            isActive: isActive.value,
            EAN: EAN.value,
            segment: segment.value,
            subSegment: subSegment.value,
            brandId: brand.value,
            categoryId: productCategory.value,
            manufacturer: manufacturer.value,
            idealPrice: idealPrice.value,
            weight: weight.value,
            priceOutlierFactor: priceOutlierFactor.value,
            priceRangeFactor: priceRangeFactor.value,
            aiSearchKeywords: aiSearchKeywords.value,
            aiFilterKeywords: aiFilterKeywords.value
        });    

    const create = async () => {}

    const update = async () => {
        var payload = getPayload();
        payload.id = params.id;
  
        await service.put(`/api/product/edit`, payload)
            .then(response => {
                const success = response?.data?.status === 200;
  
                if(success){
                  Alert("Produto atualizado com sucesso!", "", "success");
                }else{
                  Alert(response.data.title ? response.data.title : "Não foi possível atualizar o produto", "", "error");
                }
            })
    }

    React.useEffect(() => {
        async function init() {
        
            const create = location.pathname.indexOf("new") !== -1;
            let id = create ? undefined : params.id;
            getBrands();
            getProductCategories();

            if (id) {
                const edit = location.pathname.indexOf("details") === -1;
                setIsNewRegister(false);
                if (edit)
                    setRole(ROLES.EDIT);
                else
                    setRole(ROLES.READ);
    
                getProduct(id); 
            } else {
                setLoading(false);
            }
        }
        init()
    }, []);

    return (
        <main className='form-container campaign-form'>
            <div className="ml-5p">
                <Breadcrumb title={isNewRegister ? "Cadastro" : "Edição"} parent="Produtos" marginLeft={true} />
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card form-card">
                            <div className="card-body">

                                {loading && <Loading/>}

                                { !loading && <Fragment>
                                    {/* {establishmentImagePath && <img className="pdv-detail-photo" src={establishmentImagePath} alt='point of sale store' />} */}
                                    <h1 className='align-center pt-15'>{name.value}</h1>
                                    <div className="card-body">
                                        <form className="needs-validation" noValidate="" onSubmit={handleSubmit}>
                                            <div className="grid-col2">
                                                <Input label='Nome' type='text' name='name' required={true} {...name} maxLength={50} inputClassName='w-20vw' />

                                                <div>
                                                    <br/>
                                                    <div className="flex" style={{alignItems: 'end'}}>
                                                        <Switch 
                                                            handleToggle={() => isActive.setValue(!isActive.value)}
                                                            isOn={isActive.value}
                                                            id="product-status-toggle"/>
                                                        <label style={{ fontSize: '14px'}}>
                                                            &nbsp;Produto {isActive.value ? 'Ativo' : 'Inativo'}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="grid-col2">
                                                <Input label='EAN' type='text' name='EAN' required={true} {...EAN} maxLength={50} inputClassName='w-20vw' />
                                                
                                                <Select 
                                                    label='Categoria'
                                                    name='brand'
                                                    required={true}
                                                    {...productCategory}
                                                    options={productCategoriesOptions}
                                                    disabled={false}
                                                />
                                            </div>

                                            <div className="grid-col2">
                                                <Input label='Segmento' type='text' name='segment' required={false} {...segment} inputClassName='w-20vw' />
                                                <Input label='Subsegmento' type='text' name='subsegment' required={false} {...subSegment} inputClassName='w-20vw' />
                                            </div>

                                            <div className="grid-col2">
                                                <Select 
                                                    label='Marca'
                                                    name='brand'
                                                    required={false}
                                                    {...brand}
                                                    options={brandOptions}
                                                    disabled={false}
                                                />                                        
                                                <Input label='Fabricante' type='text' name='manufacturer' required={false} {...manufacturer} maxLength={50} inputClassName='w-20vw' />
                                            </div>

                                            <div className="grid-col2">
                                                <Input label='Preço ideal' type='number' name='idealPrice' required={false} {...idealPrice} inputClassName='w-20vw' />
                                                <Input label='Peso' type='number' name='weigth' required={false} {...weight} maxLength={50} inputClassName='w-20vw' />
                                            </div>

                                            <div className="grid-col2">
                                                <Input label='Outlier de Preço (Outlier Factor)' type='number' name='priceOutlierFactor' required={false} {...priceOutlierFactor} inputClassName='w-20vw' />
                                                <Input label='Variação de preço (Range Factor)' type='number' name='priceRangeFactor' required={false} {...priceRangeFactor} maxLength={50} inputClassName='w-20vw' />
                                            </div>

                                            <Input label='Filtro IA - Palavras Chaves' type='text' name='aiFilterKeywords' required={false} {...aiFilterKeywords} maxLength={50} inputClassName='w-50vw' />

                                            <TextArea label='Descrição' type='text' name='description' rows={4} maxLength={250} disabled={true} required={false} {...description} />

                                            <div className="grid-col2">
                                                <Input label='Altura' type='number' name='height' disabled={true} required={false} {...height} inputClassName='w-20vw' />
                                                <Input label='Largura' type='number' name='width' disabled={true} required={false} {...width} maxLength={50} inputClassName='w-20vw' />
                                            </div>

                                            <div className="grid-col2">
                                                <Input label='Profundidade' type='number' name='depth' disabled={true} required={false} {...depth} maxLength={50} inputClassName='w-20vw' />
                                                <Input label='Região' type='text' name='region' disabled={true} required={false} {...region} maxLength={50} inputClassName='w-20vw' />
                                            </div>
                                                
                                            <div className="grid-col2">
                                                <Input label='Data de cadastro' type='date' name='registerDate' disabled={true} required={false} {...registerDate} inputClassName='w-20vw' />
                                                {updatedByUsername.value !== null && <Input label='Atualizado por' type='text' name='updatedByUsername' disabled={true} required={false} {...updatedByUsername} maxLength={50} inputClassName='w-20vw' /> }
                                            </div>

                                            <div className='align-center'>
                                                <button className="btn btn-pill btn-outline-secondary" type="submit">Atualizar</button>
                                            </div>

                                        </form>
                                    </div>
                                </Fragment> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </main>
    );
};

export default ProductForm;