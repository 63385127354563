import React, { Fragment, useState } from 'react';
import { visitsReservationsFilters } from '../components/filters';

import { useService } from "../../../hooks/useService";
import Datatable from "../../../components/tables/table";
import { updateFilterValues } from "../../../utils/helper";
import { getVisitsReservationsStatus, getVisitsReservationsClass } from "../../../utils/validations";
import emptyImage from '../../../assets/images/emptylist.png';
import Breadcrumb from '../../../components/common/breadcrumb';
import { visitReservationsColumns as columns } from "../../../utils/tablesHelper";
import DynamicFilter from '../../../components/common/filters/dynamic_filter';

const VisitsReservationsList = ({ history }) => {

    const localStorageFilterKey = 'visits-reservation-filters';
    const [loadingTable, setLoadingTable] = useState(true);
    const [data, setData] = React.useState([]);
    const [firstLoad, setFirstLoad] = React.useState(true);
    const [pageSize, setPageSize] = React.useState(10);
    const [recordsFiltered, setRecordsFiltered] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [sortColumn, setSortColumn] = React.useState(null);
    const [sortDirection, setSortDirection] = React.useState(0);
    const service = useService();

    const [tableFilters, setTableFilters] = React.useState([...visitsReservationsFilters]);

    const getVisitsReservations = async (page = 0, currentPageSize = 10, sortColumn = null, orderDirection = 0, nameFilter = null, initialFilters) => {

        if (nameFilter === "")
            nameFilter = null;

        if (nameFilter != null)
            sortColumn = null;

        setSortColumn(sortColumn);
        setSortDirection(orderDirection);

        setPageSize(currentPageSize);
        setCurrentPage(page);

        saveFilterConfig(sortColumn, orderDirection, page, currentPageSize)

        var filters = [];

        const filterItems = initialFilters ?? tableFilters;

        filterItems.map(filter => {

            if(filter.value !== '' && filter.value != null && filter.value !== -1 && filter.value !== '-1') {
                console.log('filter', filter)
                filters.push({
                    name: filter.name,
                    value: filter.value !== 'empty' ? filter?.value : 0
                })
            }
        });

        if(filters.length === 0 && initialFilters != null){
            //is the first load
            setSortColumn('RegisterDate');
            sortColumn = 'RegisterDate';
            setSortDirection(1);
            orderDirection = 1;
        }

        var skip = 0;

        if(!isNaN(page))
            skip = page * (currentPageSize !== null ? currentPageSize : pageSize);
        
        if (skip === 0) {
            setCurrentPage(0);

            if(filters.length > 0) {
                setLoadingTable(true);
            }
        }

        var payload = {
            "sortColumn": sortColumn,
            "sortColumnDirection": orderDirection,
            "searchValue": nameFilter,
            "pageSize": currentPageSize !== null ? currentPageSize : pageSize,
            "skip": skip,
            "isActive": null,
            "filters": filters
        }

        await service.post("/api/VisitReservation/list", payload)
        .then(async response => {
            if (response === undefined || response?.data?.data === null) {
                return;
            }

            var items = response?.data?.data?.data.map(item => {
                let reservation = {
                    id: item.id,
                    posId: item.posId,
                    posName: item.posName,
                    posCity: item.posCity,
                    posRegion: item.posRegion,
                    posState: item.posState,
                    reservationDate: new Date(item.reservationDate).toLocaleDateString('pt-BR'),
                    userEmail: <p className="clickable-p pointer" onClick={() => {history.push(`/users/details/${item.userId}`)}}>{item.userEmail}</p>,
                    userId: item.userId,
                    routeIdentifier: item.routeIdentifier,
                    status: <p className={`${getVisitsReservationsClass(item.status)}`}>{getVisitsReservationsStatus(item.status)}</p>,
                    visitId: item.visitId ? (<a href={`/visits/details/${item.visitId}`} className="align-center pointer"><img className="user-icon" src={require("../../../assets/images/more.svg")} alt="magnifying glass" /></a>) : (<p>-</p>)
                };
                return reservation;
            })

            setData(items);
            if(response?.data?.data?.recordsFiltered)
                setRecordsFiltered(response.data.data.recordsFiltered);            

        if (firstLoad)
            setFirstLoad(false);

        }).finally(() => {
            setLoadingTable(false);
        });
    }

    const handleFilterReset = async () => {
        setLoadingTable(true);
        
        localStorage.removeItem(localStorageFilterKey);
        localStorage.removeItem('visits-reservation-config');
        
        setCurrentPage(0);
        setSortDirection(0);
        setSortColumn(null);
        setTableFilters([...visitsReservationsFilters])
        await getVisitsReservations(0, 10, null, 0, null, visitsReservationsFilters);
        setLoadingTable(false);
    }

    const saveFilterConfig = (sortColumn, orderDirection, page = currentPage, pageSz = pageSize) => {
        const tableConfig = {
            page: page,
            pageSize: pageSz,
            ordenation: {
                sortColumn: sortColumn,
                sortDirection: orderDirection
            }
        }
        localStorage.setItem('visits-reservation-config', JSON.stringify(tableConfig));
    }

    React.useEffect(() => {

        var filtersResult = localStorage.getItem(localStorageFilterKey);
        var page = 0;
        var pageSize = 10;
        var sortColumn = null;
        var sortDirection = 0;

        var filters = [];

        if(filtersResult != null) {
            const resultFilters = JSON.parse(filtersResult);
            filters = updateFilterValues(visitsReservationsFilters, resultFilters);
            setTableFilters(filters);                    
        }

        var tableConfigResult = localStorage.getItem('visits-reservation-config');

        if(tableConfigResult != null) {

            var tableConfig;
            if(tableConfigResult)
                tableConfig = JSON.parse(tableConfigResult);

            if(tableConfig?.page){
                setCurrentPage(tableConfig.page);
                page = tableConfig.page;
            }

            if(tableConfig?.pageSize) {
                setPageSize(tableConfig.pageSize);
                pageSize = tableConfig.pageSize;
            }
            
            if(tableConfig?.ordenation?.sortColumn) {
                setSortColumn(tableConfig.ordenation.sortColumn);
                sortColumn = tableConfig.ordenation.sortColumn;
            }

            if(tableConfig?.ordenation?.sortDirection) {
                setSortDirection(tableConfig.ordenation.sortDirection);
                sortDirection = tableConfig.ordenation.sortDirection;
            }
        }
        getVisitsReservations(page, pageSize, sortColumn, sortDirection, null, filters);
    }, []);
    
    return (
        <Fragment>
            <Breadcrumb title="Reservas de Visitas" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header title-btn">
                                <span className="d-flex">
                                    <h5>Reservas de Visitas</h5>
                                    <p className="pl-10 pt-3">({recordsFiltered} itens)</p>
                                </span>

                                <div className="flex space-between">
                                    <DynamicFilter 
                                        handleSearch={getVisitsReservations}
                                        filters={tableFilters}
                                        localStorageName={localStorageFilterKey}
                                        handleReset={handleFilterReset}
                                        handleFilterChange={setTableFilters}
                                        saveFilterState={saveFilterConfig}
                                    />
                                </div>
                            </div>

                            {!loadingTable && data.length === 0 && (
                                <div className="align-center">
                                    <div className="empty-list-txt">Nenhuma reserva de visita encontrada</div>
                                    <img className="empty-list-img" src={emptyImage} alt="empty list" />
                                </div>
                            )}

                            {loadingTable &&
                            <div className="loader-box loading" style={{minHeight: '50vh'}}>
                                <div className="loader">
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                </div>
                            </div>
                            }

                            {!loadingTable && data.length > 0 &&
                                <div className="card-body datatable-react">
                                    <div className="card-body datatable-react">
                                        <Datatable
                                            multiSelectOption={false}
                                            myData={data}
                                            loading={loadingTable}
                                            firstLoad={firstLoad}
                                            pageSize={pageSize}
                                            pagination={true}
                                            class="-striped -highlight"
                                            columns={columns}
                                            setCurrentPage={setCurrentPage}
                                            currentPage={currentPage}
                                            totalPages={Math.ceil(recordsFiltered/pageSize)}
                                            setPageSize={setPageSize}
                                            onPageChange={getVisitsReservations}
                                            selectedData={null}
                                            manual={true}
                                            sortColumn={sortColumn}
                                            sortColumnDirection={sortDirection}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default VisitsReservationsList;