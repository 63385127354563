import React, { Fragment, useState } from 'react';
import { Edit3 as Edit } from 'react-feather';
import { usersGroupsFilters } from '../components/filters';
import { Plus, ThumbsUp, ThumbsDown } from 'react-feather';

import { useService } from "../../../hooks/useService";
import { ToastContainer, toast } from "react-toastify";
import Datatable from "../../../components/tables/table";
import { updateFilterValues } from "../../../utils/helper";
import emptyImage from '../../../assets/images/emptylist.png';
import Breadcrumb from '../../../components/common/breadcrumb';
import AddButton from '../../../components/common/buttons/default';
import { usersGroupsColumns as columns } from "../../../utils/tablesHelper";
import DynamicFilter from '../../../components/common/filters/dynamic_filter';
import UsersGroupMembersModal from './components/usersGroupMembers';

const UsersGroups = ({ history }) => {

    const localStorageFilterKey = 'users-groups-filters';
    const [loadingTable, setLoadingTable] = useState(true);
    const [data, setData] = React.useState([]);
    const [firstLoad, setFirstLoad] = React.useState(true);
    const [pageSize, setPageSize] = React.useState(10);
    const [recordsFiltered, setRecordsFiltered] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [sortColumn, setSortColumn] = React.useState(null);
    const [sortDirection, setSortDirection] = React.useState(0);
    const service = useService();
    
    // show users group members modal
    const [showUsersgroupMemberModal, setshowUsersgroupMemberModal] = useState(false);
    const [usersGroupName, setUsersGroupName] = useState('');
    const [usersGroupMembers, setUsersGroupMembers] = useState([]);


    const [tableFilters, setTableFilters] = React.useState([...usersGroupsFilters]);

    const getUsersGroups = async (page = 0, currentPageSize = 10, sortColumn = null, orderDirection = 0, nameFilter = null, initialFilters) => {

        if (nameFilter === "")
            nameFilter = null;

        if (nameFilter != null)
            sortColumn = null;

        setSortColumn(sortColumn);
        setSortDirection(orderDirection);

        setPageSize(currentPageSize);
        setCurrentPage(page);

        saveFilterConfig(sortColumn, orderDirection, page, currentPageSize)

        var filters = [];

        const filterItems = initialFilters ?? tableFilters;

        filterItems.map(filter => {

            if(filter.value !== '' && filter.value != null && filter.value !== -1 && filter.value !== '-1') {
                filters.push({
                    name: filter.name,
                    value: filter.value !== 'empty' ? filter.value.trim() : null
                })
            }
        });

        if(filters.length === 0 && initialFilters != null){
            //is the first load
            setSortColumn('RegisterDate');
            sortColumn = 'RegisterDate';
            setSortDirection(1);
            orderDirection = 1;
        }

        var skip = 0;

        if(!isNaN(page))
            skip = page * (currentPageSize !== null ? currentPageSize : pageSize);
        
        if (skip === 0) {
            setCurrentPage(0);

            if(filters.length > 0) {
                setLoadingTable(true);
            }
        }

        var payload = {
            "sortColumn": sortColumn,
            "sortColumnDirection": orderDirection,
            "searchValue": nameFilter,
            "pageSize": currentPageSize !== null ? currentPageSize : pageSize,
            "skip": skip,
            "isActive": null,
            "filters": filters
        }

        await service.post("/api/usersgroup/list", payload)
        .then(async response => {
            if (response === undefined || response?.data?.data === null) {
                return;
            }

            var items = response?.data?.data?.data.map(item => {
                let userGroup = {
                    id: item.id,
                    registerDate: new Date(item.registerDate).toLocaleDateString('pt-BR'),
                    isActive: item.isActive,
                    allowedToWorkOnRoute: item.allowedToWorkOnRoute ? <ThumbsUp  className="main-color"/> : <ThumbsDown  className="error-color"/>,
                    // name: <p className="clickable-p pointer" onClick={() => {history.push(`/users/groups/details/${item.id}`)}}>{item.name}</p>,
                    name: item.name,
                    details: <div className="align-center pointer" onClick={() => {history.push(`/users/groups/${item.id}`)}}><Edit /></div>,
                    amountOfUsers: (<>
                        {
                            (item.amountOfUsers === null || item.amountOfUsers === 0) 
                            ? 
                                <p>-</p> 
                            : 
                        (
                            <div className="pointer" onClick={() => {
                                setshowUsersgroupMemberModal(true);
                                setUsersGroupName(item.name);
                                setUsersGroupMembers(item.users);
                            }}>
                                <p className={item.amountOfUsers > 0 ? 'underline': ''}>{item.amountOfUsers}</p>
                            </div>)
                        }     
                    </>),
                    amountOfRoutes: item.amountOfRoutes > 0 ? item.amountOfRoutes : '-',
                };
                return userGroup;
            })

            setData(items);
            if(response?.data?.data?.recordsFiltered)
                setRecordsFiltered(response.data.data.recordsFiltered);            

        if (firstLoad)
            setFirstLoad(false);

        }).finally(() => {
            setLoadingTable(false);
        });
    }

    const handleFilterReset = async () => {
        setLoadingTable(true);
        
        localStorage.removeItem(localStorageFilterKey);
        localStorage.removeItem('users-groups-config');
        
        setTableFilters(tableFilters.map(t => {

            if(t.type === 'date-range-picker') {
                t.valueEnd = null;
                t.valueStart = null;
            }else {
                t.value = '';
            }
            return t;
        }))
        
        setCurrentPage(0);
        setSortDirection(0);
        setSortColumn(null);
        window.location.reload();
        await getUsersGroups();
        setLoadingTable(false);
    }

    const changeActiveStatus = async (usersgroup, index) => {
        await service.post(`/api/usersgroup/active/${usersgroup.id}/${!usersgroup.isActive}`)
            .then(response => {
                if (response.status === 200) {
                    let items = [...data];
  
                    let item = items.find((it) => it.id === usersgroup.id);
                    usersgroup.active = !usersgroup.active;
                    usersgroup.isActive = !usersgroup.isActive;
                    items[index] = item;
                    setData(items);
  
                    let change = !usersgroup.isActive ? "inativado" : "ativado";
                    toast.success("Grupo de usuários " + change + " com sucesso!");
                } else {
                    let change = !usersgroup.isActive ? "inativar" : "ativar";
                    toast.error("Não foi possível " + change + " o grupo de usuários");
                }
            })
      }

    const saveFilterConfig = (sortColumn, orderDirection, page = currentPage, pageSz = pageSize) => {
        const tableConfig = {
            page: page,
            pageSize: pageSz,
            ordenation: {
                sortColumn: sortColumn,
                sortDirection: orderDirection
            }
        }
        localStorage.setItem('users-groups-config', JSON.stringify(tableConfig));
    }

    React.useEffect(() => {

        var filtersResult = localStorage.getItem(localStorageFilterKey);
        var page = 0;
        var pageSize = 10;
        var sortColumn = null;
        var sortDirection = 0;

        var filters = [];

        if(filtersResult != null) {
            const resultFilters = JSON.parse(filtersResult);
            filters = updateFilterValues(usersGroupsFilters, resultFilters);
            setTableFilters(filters);                    
        }

        var tableConfigResult = localStorage.getItem('users-groups-config');

        if(tableConfigResult != null) {

            var tableConfig;
            if(tableConfigResult)
                tableConfig = JSON.parse(tableConfigResult);

            if(tableConfig?.page){
                setCurrentPage(tableConfig.page);
                page = tableConfig.page;
            }

            if(tableConfig?.pageSize) {
                setPageSize(tableConfig.pageSize);
                pageSize = tableConfig.pageSize;
            }
            
            if(tableConfig?.ordenation?.sortColumn) {
                setSortColumn(tableConfig.ordenation.sortColumn);
                sortColumn = tableConfig.ordenation.sortColumn;
            }

            if(tableConfig?.ordenation?.sortDirection) {
                setSortDirection(tableConfig.ordenation.sortDirection);
                sortDirection = tableConfig.ordenation.sortDirection;
            }
        }
        getUsersGroups(page, pageSize, sortColumn, sortDirection, null, filters);
    }, []);
    
    return (
        <Fragment>
            <Breadcrumb title="Grupos de Usuários" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header title-btn">
                                <span className="d-flex">
                                    <h5>Grupos de Usuários</h5>
                                    <p className="pl-10 pt-3">({recordsFiltered} itens)</p>
                                </span>
                                <AddButton 
                                    description="Novo grupo"
                                    icon={<Plus/>}
                                    onClick={() => history.push("/users/groups/new")}
                                />

                                <div className="flex space-between">
                                    <DynamicFilter 
                                        handleSearch={getUsersGroups}
                                        filters={tableFilters}
                                        localStorageName={localStorageFilterKey}
                                        handleReset={handleFilterReset}
                                        handleFilterChange={setTableFilters}
                                        saveFilterState={saveFilterConfig}
                                    />
                                </div>
                            </div>

                            {!loadingTable && data.length === 0 && (
                                <div className="align-center">
                                    <div className="empty-list-txt">Nenhum grupo de usuários encontrado</div>
                                    <img className="empty-list-img" src={emptyImage} alt="empty list" />
                                </div>
                            )}

                            {loadingTable &&
                            <div className="loader-box loading" style={{minHeight: '50vh'}}>
                                <div className="loader">
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                    <div className="line bg-primary"></div>
                                </div>
                            </div>
                            }

                            {!loadingTable && data.length > 0 &&
                                <div className="card-body datatable-react">
                                    <div className="card-body datatable-react">
                                        <Datatable
                                            multiSelectOption={false}
                                            myData={data}
                                            loading={loadingTable}
                                            firstLoad={firstLoad}
                                            pageSize={pageSize}
                                            pagination={true}
                                            class="-striped -highlight"
                                            columns={columns}
                                            setCurrentPage={setCurrentPage}
                                            currentPage={currentPage}
                                            totalPages={Math.ceil(recordsFiltered/pageSize)}
                                            setPageSize={setPageSize}
                                            onPageChange={getUsersGroups}
                                            selectedData={null}
                                            manual={true}
                                            sortColumn={sortColumn}
                                            sortColumnDirection={sortDirection}
                                            changeStatus={changeActiveStatus}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <UsersGroupMembersModal 
                isOpen={showUsersgroupMemberModal}
                toggle={() => setshowUsersgroupMemberModal(false)}
                usergroupName={usersGroupName}
                users={usersGroupMembers}
            />

            <ToastContainer />
        </Fragment>
    );
};

export default UsersGroups;