export const VISITSSTATUS = {
    0: 'Aguardando AI',
    1: 'Enviado para AI',
    2: 'Processado pela AI'
}

export const RESERVATIONS_VISITS_STATUS = {
    0: 'Reservada',
    1: 'Iniciada',
    2: 'Finalização notificada',
    3: 'Finalizada',
    4: 'Cancelada pelo usuário',
    5: 'Cancelada pelo sistema'
}

export const IMAGERATE = {
    0: 'None',
    1: 'Good',
    2: 'Bad'
}

export const ROLES = {
    CREATE: 0,
    READ: 1,
    EDIT: 2
}

export const VIEWS_ROLES = {
    ALL: 0,
    CAMPAIGNS: 1
}

export const WEEK_DAY = {
    0: 'Domingo',
    1: 'Segunda-feira',
    2: 'Terça-feira',
    3: 'Quarta-feira',
    4: 'Quinta-feira',
    5: 'Sexta-feira',
    6: 'Sábado'
}

export const USER_WALLET_TRANSACTION_REFERENCE = {
    0: 'Aprovação de visita',
    1: 'Visita realizada',
    2: 'Pagamento efetuado',
    3: 'Pagamento adicional efetuado',
    4: 'Usuário registrou-se através de convite e obteve 3 visitas aprovadas',
    5: 'Usuário enviou convite e novo usuário realizou 3 visitas'
}

export const SURVEY_ANSWERS_TYPE = {
    Text: 0,
    Camera: 1,
    OptionalDecimal: 2,
    Bool: 3,
    QRCode: 4,
    BarCode: 5,
    InBulk: 6
}

export const SURVEY_ANSWERS_TYPE_TEXT = {
    0: 'Texto',
    1: 'Câmera',
    2: 'Número decimal (opcional)',
    3: 'Sim/Não',
    4: 'QR Code',
    5: 'Código de barras',
    6: 'Resposta a granel'
}